@import '~@assets/styles/_mixins.scss';
@import '~@assets/styles/_colors.scss';

.peakAvailable {
  @include flexbox(center, center);

  .tokenWrapper {
    font-size: inherit;
    @include flexbox(center, center);
    margin-right: 4px;
  }

  .peak {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
  }

  .token {
    flex-shrink: 0;
  }

  .peak,
  .token {
    font-size: inherit;

    &.regular {
      font-weight: 400;
    }

    &.bold {
      font-weight: 700;
    }

    &.white {
      color: $white;
    }

    &.green {
      color: $green;
    }

    &.greyLight {
      color: #999999;
    }
  }

  .available {
    font-size: inherit;

    &.grey {
      color: $grey;
    }

    &.green {
      color: $green;
    }

    &.greyLight {
      color: #999999;
    }
  }
}

.large {
  font-size: 22px;
}

.small {
  font-size: 16px;
}

.micro {
  font-size: 12px;
}