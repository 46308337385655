@import "./variables";

@mixin flexbox($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin flexboxInline($align, $justify) {
  display: inline-flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin forPhoneMini {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin forPhone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin forPhoneBig {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin forTablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin forLaptop {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin forMinDesktop {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin transition($properties: all, $duration: $transition-duration, $easing: $transition_easing) {
  $transitionList: '';

  @each $property in $properties {
    @if ($transitionList == '') {
      $transitionList: $property + ' ' + $duration + ' ' + $easing;
    } @else {
      $transitionList: $transitionList + ', ' + $property + ' ' + $duration + ' ' + $easing;
    }
  };
  transition: $transitionList;
}

@mixin buttonDefault {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

@mixin walletContainer {
  @include flexbox(center, flex-start);
  padding: 6px 14px 6px 7px;
  border: 1px solid #424242;
  border-radius: 40px;
  background-color: $grey-dark;
  width: 100%;
}

@mixin walletContainerText {
  margin-left: 9px;
  margin-top: 4px;
  line-height: 26px;
  font-weight: 800;
  color: $white;
}