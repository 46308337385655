@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

$dots-width-mobile: 17px;

.timer {
  @include flexboxInline(center, center);
  box-sizing: border-box;
  padding: 17px 16px;
  background-color: $grey-dark;
  border-radius: 16px;

  @include forPhone {
    flex-wrap: wrap;
    max-width: 320px;
  }

  .dots {
    padding: 0 7px;
    box-sizing: border-box;
    color: $grey;
    font-size: 36px;

    @include forTablet {
      padding: 0 3px;
      width: $dots-width-mobile;
    }
  }

  .dotsNoPhone {
    @include forPhone {
      display: none;
    }
  }

  .firstLineMobile {
    @include forPhone {
      margin-bottom: $dots-width-mobile;
    }
  }

  .block {
    padding: 15px 12px;
    box-sizing: border-box;
    min-width: 84px;
    text-align: center;
    border-radius: 16px;
    background-color: $dark;

    @include forTablet {
      padding: 12px 8px;
      min-width: 80px;
    }

    @include forPhone {
      width: calc(50% - #{$dots-width-mobile / 2});
    }
  }

  .blockValue {
    font-size: 36px;
    font-weight: 600;
    color: $white;
  }

  .blockDescription {
    display: block;
    color: $grey;
  }
}