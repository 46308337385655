@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

.pools {
  .h2 {
    color: $grey;
    text-align: center;
  }

  .h2Hidden {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .mask {
    margin-bottom: calc(10vw + 112px);
  }

  .steps {
    margin: 0 -50px;
    @include flexbox(center, center);
    padding-top: 2.5vw;
    padding-bottom: 7vw;

    @media (min-width: 1500px) {
      padding-bottom: 3vw;
    }

    @include forLaptop {
      margin: 0;
    }

    @include forMinDesktop {
      padding-bottom: 0;
    }

    @include forTablet {
      padding-bottom: 10vw;
    }

    @include forPhoneBig {
      padding-bottom: 25vw;
    }

    @include forPhone {
      padding-bottom: 40vw;
    }

    @include forPhoneMini {
      padding-bottom: 50vw;
    }
  }

  .step {
    color: $white;
    font-size: 22px;
    line-height: 32px;

    &Green {
      color: $green;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .sectionTimer {
    position: absolute;
    width: 100%;
    bottom: -10vw;
    left: 50%;
    transform: translateY(30%) translateX(-50%);
    text-align: center;

    @include forMinDesktop {
      bottom: -9vw;
    }
  }

  .timerHeader {
    margin-bottom: 30px;
  }

  .sectionCards {
    margin-top: 48px;
    margin-bottom: 48px;

    @include forLaptop {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .cardsTitle {
    margin-bottom: 26px;
  }

  .cards {
    width: 100%;
    @include flexbox(center, space-between);

    @include forLaptop {
      flex-wrap: wrap;
    }
  }

  .card {
    max-width: calc(50% - 12px);
    @media (max-width: 1200px) and (min-width: 1025px) {
      padding: 24px 17px;
    }

    @include forLaptop {
      max-width: none;
      margin: 0;
      width: 100%;
      height: auto;
    }

    &:not(:first-child) {
      @include forLaptop {
        margin-top: 15px;
      }
    }
  }

  .faq {
    margin-top: 146px;
    margin-bottom: 83px;

    @include forLaptop {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    @include forPhone {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}