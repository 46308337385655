@import "../../assets/styles/colors";

.balanceInfo {
  padding: 14px 20px 11px;
  background-color: $grey-dark;
  border: 1px solid #424242;
  border-radius: 40px;
  display: inline-flex;
  max-width: 100%;

  .value,
  .currency {
    line-height: 19px;
    letter-spacing: 0.2px;
  }

  .value {
    color: $white;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .currency {
    margin-left: 23px;
    color: $green;
    font-weight: 600;
  }
}