@import '~@assets/styles/_colors.scss';
@import '~@assets/styles/_mixins.scss';
@import '~@assets/styles/_variables.scss';

$margin-bottom: 25px;

.manage {
  .mask {
    padding-top: 80px;
    text-align: center;

    .title {
      margin-bottom: $margin-bottom;
      text-align: center;
      color: $grey;
    }

    &::before {
      height: 15vw;

      @include forMinDesktop {
        height: 10vw;
      }
    }
  }

  .container {
    @include flexbox(center, center);
    flex-direction: column;

    .controlsGroup {
      @include flexbox(center, center);
      box-sizing: border-box;
      padding: 0 24px;
      margin-bottom: 48px;
      width: $balance-block-width;
    }

    .controlWithdraw {
      margin-left: 22px;
    }

    .balanceBlock {
      margin-bottom: $margin-bottom;
    }

    .peakAvailable {
      margin: 52px 0;
    }
  }

  .hidden {
    display: none;
  }
}