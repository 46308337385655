@import "~@assets/styles/_mixins.scss";
@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

.buttonConnect {
  @include buttonDefault;
  @include walletContainer;
  align-content: center;
  @include transition((background-color, background-color));

  &:hover,
  &:focus {
    background-color: $dark;
  }

  &[disabled] {
    background-color: $dark;
    cursor: default;
  }

  .value {
    @include walletContainerText;
  }

  &.error {
    justify-content: center;
    padding: 13px 27px;
    border-color: transparent;
    background-color: $red;
    color: $white;

    &:hover,
    &:focus {
      background-color: $red-hover;
    }
  }
}