@import '~@assets/styles/_mixins.scss';
@import '~@assets/styles/_colors.scss';

.walletAddress {
  @include walletContainer;
  box-sizing: border-box;

  .valueWrapper {
    width: calc(100% - 44px);
    @include flexbox(center, center);
  }

  .value,
  .valuePartFirst {
    @include walletContainerText;
  }

  .value {
    margin-left: 0;
  }

  .valuePartFirst {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}