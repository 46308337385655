@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

$border-radius: 10px;

.dropdown {
  width: 100%;

  .summary {
    position: relative;
    padding: 22px 50px 18px 20px;
    font-weight: 600;
    line-height: 19px;
    background-color: $white;
    border-radius: $border-radius;
    outline: none;
    cursor: pointer;

    &::-webkit-details-marker { display: none; }
    &::-moz-list-bullet { list-style-type: none; }

    &::before {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-image: url('~@assets/icons/arrow.svg');
      @include transition(transform);
    }
  }

  .body {
    position: relative;
    padding: 25px 20px 25px;
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $grey;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background-color: $grey-light;
    }
  }

  &[open] .summary {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}