@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

.mask {
  position: relative;
  background-color: $dark;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 10vw;
    display: block;
    background-image: url('~@assets/img/mask.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;

    @include forMinDesktop {
      height: 10vw;
    }
  }
}