@import '~@assets/styles/_colors.scss';
@import '~@assets/styles/_mixins.scss';
@import '~@assets/styles/_variables.scss';

.shadow {
  box-shadow: 0 24px 24px rgba(50, 50, 71, 0.1), 0 40px 48px rgba(50, 50, 71, 0.25);
}

.balanceBlock {
  position: relative;
  width: $balance-block-width;
  height: 144px;
  border-radius: 16px;
  background: $grey-dark;

  @include forPhone {
    width: 100%;
    height: auto;
  }

  .background {
    position: absolute;
    margin-left: 189px;
    width: calc(100% - 189px);
    height: 100%;
    background: url('~@assets/img/CardBackground.png');
    border-radius: inherit;
  }

  .content {
    position: relative;
    z-index: 1;
    @include flexbox(flex-start, space-between);
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 24px 24px;
    border-radius: inherit;

    .header {
      width: 100%;
      @include flexbox(center, space-between);

      @include forPhone {
        flex-direction: column;
      }
    }

    .token {
      font-weight: 700;
      color: $white;
    }

    .block {
      @include flexbox(flex-end, space-between);
      width: 100%;

      &.blockPeak {
        @include forPhone {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 10px;
        }
      }

      .amount {
        font-size: 24px;
        font-weight: 700;
        color: $green;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .amountMaxWidth {
        max-width: 60%;

        @include forPhone {
          max-width: 100%;
        }
      }

      .rewards {
        display: flex;
        max-width: 40%;
        margin-left: 10px;
        color: $grey;
        font-weight: 700;

        @include forPhone {
          margin-top: 5px;
          margin-left: 0;
          max-width: 100%;
        }
      }

      .rewardsCount {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}