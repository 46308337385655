@font-face {
	font-family: 'Cerebri Sans Pro';
	src:
		local('CerebriSansPro-Medium'),
		url('~@assets/fonts/CerebriSansPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Cerebri Sans Pro';
	src:
		local('CerebriSansPro-Medium'),
		url('~@assets/fonts/CerebriSansPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Cerebri Sans Pro';
	src:
		local('CerebriSansPro-SemiBold'),
		url('~@assets/fonts/CerebriSansPro-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Cerebri Sans Pro';
	src:
		local('CerebriSansPro-Bold'),
		url('~@assets/fonts/CerebriSansPro-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}