@import "~@assets/styles/_mixins.scss";
@import "~@assets/styles/_colors.scss";

.steps {
  list-style-type: none;
  padding-left: 0;
  @include flexbox(stretch, center);
  counter-reset: counter;
  margin: -26px;
  max-width: 1072px;

  @include forLaptop {
    flex-direction: column;
    margin: -13px;
  }

  @include forTablet {
    margin: -10px 0;
  }

  .step:nth-child(3n + 1) {
    max-width: 356px;
  }

  .step:nth-child(3n + 2) {
    max-width: 410px;
  }

  .step:nth-child(3n + 1),
  .step:nth-child(3n + 2) {
    @include forLaptop {
      max-width: none;
    }
  }

  .step {
    @include flexbox(center, flex-start);
    position: relative;
    padding: 26px 26px 26px 100px;
    box-sizing: border-box;

    @include forLaptop {
      padding: 13px 13px 13px 87px;
    }

    @include forTablet {
      padding: 10px 0 10px 40px;
    }

    &::before {
      counter-increment: counter;
      content: counter(counter);
      position: absolute;
      align-items: center;
      left: 48px;
      top: 53%;
      transform: translateY(-50%);
      font-size: 64px;
      line-height: 64px;
      font-weight: 600;
      color: $green;

      @include forLaptop {
        left: 35px;
        font-size: 50px;
        line-height: 50px;
      }

      @include forTablet {
        left: 10px;
        font-size: 30px;
        line-height: 30px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: $green;

      @include forLaptop {
        left: 13px;
      }

      @include forTablet {
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
    }
  }
}