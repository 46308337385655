$dark: #141516;
$grey-dark: #232626;
$grey-light: #EAEFF4;
$yellow: #F6C343;

$grey: #5B718F;
$grey-hover: #536782;

$blue: #1C68D1;
$blue-hover: #175FC4;

$green: #00D97D;
$green-hover: #02CF78;

$transparent: transparent;
$transparent-hover: #FFF;

$red: #CA3030;
$red-hover: #BA2B2B;

$icon: #EAEFF4;

$white: #FFF;
