@import '~@assets/styles/_mixins.scss';
@import '~@assets/styles/_colors.scss';

.overlay {
  position: fixed;
  z-index: 100;
  inset: 0;
  background-color: rgba(20, 21, 22, 0.55);
  backdrop-filter: blur(45px);
}

.modal {
  @include flexbox(center, flex-start);
  flex-direction: column;

  position: absolute;
  outline: none;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 445px;
  height: 304px;
  padding: 7px 0 50px;
  background: $grey-dark;
  border-radius: 16px;
  box-shadow: 0 24px 24px rgba(50, 50, 71, 0.1), 0 40px 48px rgba(50, 50, 71, 0.25);

  @include forPhone {
    width: 100%;
  }

  .header {
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 400;

    .red {
      color: $red;
      font-weight: inherit;
    }

    .title {
      color: $grey-light;
      font-weight: inherit;
    }

    .icon {
      @include buttonDefault;
      position: absolute;
      color: $icon;
      top: 20px;

      & i {
        font-size: 13px;
      }
    }

    .iconClose {
      right: 18px;
    }

    .iconBack {
      font-size: 18px;
      left: 18px;
    }
  }

  .content {
    box-sizing: border-box;
    padding: 0 80px;
    width: 100%;
    flex: 1;
    color: $grey-light;
  }
}