@import "~@assets/styles/colors";
@import "~@assets/styles/mixins";
@import "~@assets/styles/variables";

.button {
  @include flexboxInline(center, center);
  @include buttonDefault;
  padding: 14px 27px 11px;
  line-height: 19px;
  border-radius: 20px;
  color: $white;
  letter-spacing: 0.5px;
  @include transition([background-color, color, border-color]);

  &[disabled] {
    cursor: default;
  }

  &.fluid {
    width: 100%;
  }

  &.blue {
    background-color: $blue;

    &:not([disabled]):hover {
      background-color: $blue-hover;
    }
  }

  &.red {
    background-color: $red;

    &:not([disabled]):hover {
      background-color: $red-hover;
    }
  }

  &.green {
    background-color: $green;

    &[disabled] {
      background-color: #3D4040;
      color: #999999;
    }

    &:not([disabled]):hover {
      background-color: $green-hover;
    }
  }

  &.gray {
    background-color: $grey;

    &[disabled] {
      background-color: #3D4040;
      color: #999999;
    }

    &:not([disabled]):hover {
      background-color: $grey-hover;
    }
  }

  &.grayDark {
    background-color: $grey-dark;
    border: 1px solid #424242;
    color: $green;

    &:not([disabled]):hover {
      background-color: #424242;
    }
  }

  &.transparent {
    border: 1px solid $white;

    &[disabled] {
      background-color: #3D4040;
      color: #999999;
      border-color: transparent;
    }

    &:not([disabled]):hover {
      background-color: $white;
      border-color: transparent;
      color: $grey-dark;
    }
  }
}