@import "~@assets/styles/_mixins.scss";
@import "~@assets/styles/_colors.scss";

.header {
  padding: 40px 0;
  background-color: $dark;

  @include forLaptop {
    padding: 5px 0;
  }

  @include forPhone {
    padding: 20px 0;
  }

  .inner {
    max-width: 1440px;
    @include flexbox(center, space-between);

    @include forLaptop {
      flex-direction: column;
    }
  }

  .link {
    line-height: 0;
  }

  .info {
    margin-left: 15px;

    @include forLaptop {
      @include flexbox(center, center);
      flex-direction: column;
      margin-left: 0;
      width: 100%;
    }
  }

  .currencyInfo,
  .buttonAddress {
    margin-left: 15px;
    min-width: 0;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0;
    }

    @include forLaptop {
      margin: 10px 0 0 0;
    }
  }

  .currencyInfo {
    @include forLaptop {
      justify-content: center;
      width: 50%;
    }

    @include forPhone {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .buttonAddress {
    @include flexboxInline(center, center);
    max-width: 157px;

    .partOne {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include forLaptop {
      width: 100%;
      max-width: 50%;
      margin-left: 0;
      margin-top: 10px;
    }

    @include forPhone {
      max-width: none;
    }
  }
}