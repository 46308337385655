@import '~@assets/styles/_mixins.scss';

.manageModal {
  .content {
    @include flexbox(center, space-between);
    flex-direction: column;
    padding: 13px 62px 0;

    @include forPhone {
      padding: 13px 30px 0;
    }

    .depositGroup {
      @include flexbox(center, space-around);
      width: 266px;
    }
  }

  .approved {
    margin-top: -8px;
  }
}