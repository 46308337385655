@import "~@assets/styles/_mixins.scss";
@import "~@assets/styles/_colors.scss";

.card {
  position: relative;
  box-sizing: border-box;
  width: 448px;
  height: 134px;
  padding: 24px 27px;
  border-radius: 16px;
  background-color: $grey-dark;
  box-shadow: 0 24px 24px rgba(50, 50, 71, 0.1), 0 40px 48px rgba(50, 50, 71, 0.25);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    transform: translateY(-50%);
  }

  &.eth:before {
    width: 124px;
    height: 100%;
    background-image: url('~@assets/icons/ethOpacity.svg');
  }

  &.btc:before {
    width: 115px;
    height: 100%;
    background-image: url('~@assets/icons/btcOpacity.svg');
  }

  .inner {
    position: relative;
  }

  .topRow {
    @include flexbox(center, space-between);
    @include forPhoneBig {
      flex-wrap: wrap;
    }
  }

  .title {
    margin: 0 auto 0 10px;
    font-weight: 700;
    font-size: 16px;
    color: $white;
  }

  .button {
    margin-left: 15px;

    @include forPhoneBig {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }
  }

  .link {
    display: inline-block;
    margin-top: 20px;
    font-size: 14px;
    color: $green;
    outline: none;

    &:hover {
      color: $green-hover;
      text-decoration: underline;
    }
  }

  .bottomRow {
    @include flexbox(center, space-between);
    margin-top: 20px;
  }

  .apy,
  // .percentage {
  //   font-size: 24px;
  //   font-weight: 800;
  // }

  .apy {
    color: $white;
  }

  // .percentage {
  //   color: $green;
  // }
}