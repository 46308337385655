@import '~@assets/styles/_mixins.scss';

.changeAccountModal {
  .content {
    @include flexbox(center, space-between);
    flex-direction: column;
    padding-top: 30px;
  }

  .buttonContainer {
    width: 100%;
    @include flexbox(center, center);
  }

  .buttonContainerWC {
    justify-content: space-between;

    .button {
      width: calc(50% - 9px);
    }
  }
}