@import "~@assets/styles/_colors.scss";
@import "~@assets/styles/_mixins.scss";

.faq {
  padding: 47px 135px 79px;
  border-radius: 16px;
  background-color: $grey-light;

  @include forLaptop {
    padding: 40px 70px 60px;
  }

  @include forTablet {
    padding: 40px 40px 40px;
  }

  @include forPhone {
    padding: 20px 20px 20px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 0;
    color: $grey;
    text-align: center;
  }

  .list {
    margin-top: 26px;
    list-style-type: none;
    padding-left: 0;

    @include forPhone {
      margin-top: 10px;
    }
  }

  .item {
    &:not(:first-child) {
      margin-top: 29px;

      @include forTablet {
        margin-top: 20px;
      }

      @include forPhone {
        margin-top: 10px;
      }
    }
  }
}