@import-normalize;
@import '~@assets/styles/_fontello.scss';
@import '~@assets/styles/_fonts.scss';
@import '~@assets/styles/_mixins.scss';

* {
  font: 16px 'Cerebri Sans Pro', sans-serif;
}

p {
  margin: 0;
}

h2 {
  font-size: 22px;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
}

.container {
  box-sizing: border-box;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 120px;
  padding-right: 120px;

  @include forLaptop {
    padding: 0 60px;
  }

  @include forTablet {
    padding: 0 40px;
  }

  @include forPhone {
    padding: 0 20px;
  }
}