@import "~@assets/styles/mixins";
@import "~@assets/styles/colors";

.inputWrapper {
  @include flexbox(center, center);
  background-color: $grey-dark;
  border: 1px solid #424242;
  border-radius: 40px;
  width: 100%;
  overflow: hidden;

  .inputInner {
    @include flexbox(center, center);
    min-width: 0;
    padding: 13px 20px 10px;
    box-sizing: border-box;
  }

  .label {
    flex-shrink: 0;
    padding-left: 18px;
    color: $green;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .input {
    padding: 0;
    min-width: 0;
    border: none;
    background-color: transparent;
    outline: none;
    color: $white;
    line-height: 19px;
    -moz-appearance:textfield;
    letter-spacing: 0.4px;
    @include transition(border-color);

    &::placeholder {
      opacity: 0.5;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .buttonMax {
    position: relative;
    @include buttonDefault;
    height: 100%;
    padding: 0 20px;
    color: $grey;
    @include transition(color);

    &:hover {
      color: $grey-hover;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 26px;
      background-color: $grey;
    }
  }
}